import * as NavigationConstants from "../actions/constants/navigation";
import { push } from "connected-react-router";
import { takeLatest, put } from "redux-saga/effects";

function* NavigateTo(action) {
	yield put(push(action.destination));
}

export default function* navigationSagas() {
	//Take Latest
	yield takeLatest(NavigationConstants.NAVIGATE_TO, NavigateTo);
}
