import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import rootSaga from "../sagas/index";
import rootReducer from "../reducers/index";
import Environment from "../../env/environment";

export const history = createBrowserHistory();

const configureStore = () => {
	const sagaMiddleware = createSagaMiddleware();
	const middleware = [sagaMiddleware, routerMiddleware(history)];
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const configuredStore = createStore(
		rootReducer(history),
		Environment.environmentName === "production"
			? applyMiddleware(...middleware)
			: composeEnhancers(applyMiddleware(...middleware))
	);

	sagaMiddleware.run(rootSaga);

	return configuredStore;
};

const store = configureStore();

export default store;
