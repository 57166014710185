import React from 'react';
import ReactDOM from 'react-dom';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { history } from './redux/stores/store';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routing/routes';

import "./assets/styles/index.css";
import 'bootstrap/dist/css/bootstrap.css';
import Environment from './env/environment';

const rootElement = document.getElementById('root');
rootElement.className += ` client-${Environment.clientId}`;

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history} children={<Routes  />} />
	</Provider>,
	rootElement// document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
