import React from "react";
import Form from "react-bootstrap/Form";

const Select = (props) => {
	return (
		<>
			<Form.Group>
				<Form.Label>{props.Label}</Form.Label>
				<Form.Control
					as={"select"}
					onChange={(e) => props.onChange(e.target.value)}
					// defaultValue={props.value}
					value={props.value || ''}
					className={props.className}
				>
					{ !props.isRequired && <option value="">{ props.noneText || '' }</option> }
					{
						props.options &&
						props.options.map(opt => <option key={opt.value} value={opt.value}>{opt.text}</option>)
					}
				</Form.Control>
			</Form.Group>
		</>
	);
};
export default Select;
