import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Alert } from "react-bootstrap";

import { GroupLoadActiveActionCreator } from "../../redux/actions/creators/groups";
import CustomSpinner from "../../components/custom-spinner";
import TextBox from "../../components/text-box";
import Select from "../../components/select";
import CustomButton from "../../components/custom-button";
import { SendNotificationActionCreator } from "../../redux/actions/creators/notifications";
import { NewsLoadCreator } from "../../redux/actions/creators/news";

const Notifications = (props) => {
	const [selectedGroupIds, setSelectedGroupIds] = useState([]);
	const [messageTitle, setMessageTitle] = useState('');
	const [messageBody, setMessageBody] = useState('');
	const [selectedNewsItemId, setSelectedNewsItemId] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const newsItemOptions = props.newsItems
		.filter(
			x => {
				const newsGroupIds = x.groupIds || [];
				const index = selectedGroupIds.findIndex(g => !newsGroupIds.includes(g));

				return index < 0;
			}
		)
		.sort((a,b) => (new Date(b.dateUtc)) - (new Date(a.dateUtc)))
		.map(
			x => {
				const dt = new Date(x.dateUtc);
				return { value: `${x.id}`, text: `${x.title} - [${dt.getMonth()+1}/${dt.getDate()}/${dt.getFullYear()}]`};
			}
		);
	
	const toggleGroupSelection = (groupId) => {
		const index = selectedGroupIds.findIndex(x => x === groupId);
		if (index < 0) {
			setSelectedGroupIds([...selectedGroupIds, groupId]);
		} else {
			let groups = [...selectedGroupIds];
			groups.splice(index, 1);

			setSelectedGroupIds(groups);
		}
	};

	const toggleAll = () => {
		if (selectedGroupIds.length === props.groups.length) {
			setSelectedGroupIds([]);
		} else {
			setSelectedGroupIds([...props.groups.map(x => x.groupId)]);
		}
	};

	const handleSend = () => {
		if (props.isLoadingGroups || props.isLoadingNews || props.isSending) {
			// already loading, return
			return;
		}

		if (selectedGroupIds.length === 0) {
			setErrorMessage('Must select at least 1 group.');
			return;
		}

		if (messageTitle.trim().length === 0) {
			setErrorMessage('Invalid Title.');
			return;
		}

		if (messageBody.trim().length === 0) {
			setErrorMessage('Invalid Body.');
			return;
		}

		let newsItemId = 0;
		if (selectedNewsItemId) {
			newsItemId = Number(selectedNewsItemId);
			if (newsItemId == NaN || newsItemId == 0) {
				setErrorMessage('Error sending selected news item.')
				setSelectedNewsItemId('');
				return;
			}
		}

		setErrorMessage('');

		const pushNotification = {
			groupIds: [...selectedGroupIds],
			title: messageTitle,
			body: messageBody,
			newsItemId: newsItemId
		};

		props.SendNotification(pushNotification);

		setSelectedGroupIds([]);
		setMessageTitle('');
		setMessageBody('');
		setSelectedNewsItemId('');
	};
	
	useEffect(() => {
		props.LoadGroups();
		props.LoadNewsItems();
		setSelectedGroupIds([]);
		setMessageTitle('');
		setMessageBody('');
		setSelectedNewsItemId('');
	}, []);

	let error = null;
	if (errorMessage != "") {
		error = <Alert variant={"danger"}>{errorMessage}</Alert>;
	}

	return (
			<>
				<h1>Send Push Notifications</h1>
				<br />
				<Row id="wrapper">
					<Col id="groupList" data-qa="groupList" md={4}>
						<h3>Select Groups:</h3>
						{ (props.isLoadingGroups || props.isLoadingNews) && <CustomSpinner />}
						{ !props.isLoadingGroups && !props.isLoadingNews &&
						<div style={{ maxHeight: "80%", overflowY: "scroll" }}>
							<div onClick={() => toggleAll()} className={`push-notification-group${selectedGroupIds.length === props.groups.length ? ' selected-group' : ''}`}>All Groups</div>
							{ props.groups.map(
									group => (
										<div
											key={group.groupId}
											onClick={() => toggleGroupSelection(group.groupId)}
											className={`push-notification-group${selectedGroupIds.includes(group.groupId) ? ' selected-group' : ''}`}
										>{group.groupName}</div>
									)
								)
							}
						</div> }
					</Col>
					<Col id="message" md={8}>
						<h3>Message:</h3>
						<TextBox
							Label={"Title"}
							type={"text"}
							value={messageTitle}
							onChange={(value) => setMessageTitle(value)}
						/>
						<TextBox
							Label={"Body"}
							type={"textarea"}
							rows={'3'}
							value={messageBody}
							onChange={(value) => setMessageBody(value)}
						/>
						<Select Label={"News Article"}
							onChange={(value) => setSelectedNewsItemId(value)}
							value={selectedNewsItemId}
							options={newsItemOptions}
							noneText={"Select..."}
						/>
						<CustomButton text={"Send"} disabled={props.isSending} isLoadingButton={props.isSending} onClick={() => handleSend()} />
						{error}
					</Col>
				</Row>
				
			</>
	);
};

export const MapStateToProps = (state) => {
	return {
		groups: state.groups.activeGroups,
		isLoadingGroups: state.groups.isLoading,
		isLoadingNews: state.news.isLoading,
		isSending: state.notifications.isSending,
		newsItems: state.news.newsItems
	};
};

export const MapDispatchToProps = (dispatch) => {
	return {
		LoadGroups: () => dispatch(GroupLoadActiveActionCreator()),
		SendNotification: (notification) => dispatch(SendNotificationActionCreator(notification)),
		LoadNewsItems: () => dispatch(NewsLoadCreator(true))
	};
};

export default connect(MapStateToProps, MapDispatchToProps)(Notifications);
