import React from "react";
import { connect } from "react-redux";

import { LogoutActionCreator } from "../../redux/actions/creators/authentication";

const Logout = (props) => {
	props.Logout();
	
	return <div>Logging out...</div>;
};

const mapDispatchToProps = (dispatch) => {
	return {
		Logout: () => dispatch(LogoutActionCreator())
	};
};

const mapStateToProps = (state) => {
	return {
		
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
