import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../pages/login/login";
import Dashboard from "../pages/dashboard/dashboard";
import Group from "../pages/groups/group";
import News from "../pages/news/news";
import Notifications from "../pages/notifications/notifications";
import Users from "../pages/users/users";
import Logout from "../pages/logout/logout";

const MainRouter = (props) => {
	
	const navigationPath = props.user?.navigationValues?.path;
	
	let results = (
		<React.Fragment>
			{navigationPath && <Redirect to={navigationPath} />}
			{window.location.pathname === "/Login" ? <Redirect to={"/"} /> : null}

			<Switch>
				<Route path="/groups" component={Group} />
				<Route path="/news" component={News} />
				{ props.isPushUser && <Route path="/notifications" component={Notifications} /> }
				{ props.isAdmin && <Route path="/users" component={Users} /> }
				<Route path="/logout" component={Logout} />
        		<Route exact path="/" component={Dashboard} />
			</Switch>
		</React.Fragment>
	);

	if (!props.isAuthenticated) {
		results = (
			<React.Fragment>
				<Switch>
          			<Route path="/" component={Login} />
				</Switch>
			</React.Fragment>
		);
	}
	return results;
};

export default MainRouter;
