import React from "react";

import GroupsRouter from "../../routing/groups-router";

const Group = (props) => {
	
	return (
			<>
				<GroupsRouter />
			</>
	);
};

export default Group;
