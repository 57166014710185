import axios from "axios";
import moment from "moment";
import qs from "qs";
import jwtDecode from "jwt-decode";
import { push } from "connected-react-router";

import Environment from "../env/environment";
import store from "../redux/stores/store";

const publicClient = () => {
	
	var headers = {
		'X-ClientId': Environment.clientId
	};

	var client = axios.create({
		baseURL: Environment.baseApiUrl,
		headers: headers
	});

	client.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			//console.log("Public Client Successful Interceptor", response);
			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			console.log("Public Client Error Interceptor", error);
			return Promise.reject(error);
		}
	);

	return client;
};

const authenticatedClient = async () => {
	let token = await localStorage.getItem("@Token");
	let expiration = await localStorage.getItem("@TokenExpiration");
	let refreshToken = await localStorage.getItem("@RefreshToken");
	const expDate = moment.unix(expiration);

	const now = moment();
	if (token === null) {
		push("/Login");
	}
	if (expDate.diff(now, "minute") <= 5) {
		let data = qs.stringify({
			grant_type: "refresh_token",
			client_id: Environment.identity.clientId,
			client_secret: Environment.identity.clientSecret,
			refresh_token: refreshToken,
		});

		axios
			.post(Environment.baseApiUrl + "/connect/token", data)
			.then((response) => {
				var decoded = jwtDecode(response.data.access_token);
				localStorage.setItem("@Token", response.data.access_token);
				localStorage.setItem("@TokenExpiration", decoded.exp.toString());
				localStorage.setItem("@RefreshToken", response.data.refresh_token);

				token = response.access_token;
			})
			.catch(async (error) => {
				await localStorage.removeItem("@Token");
				await localStorage.removeItem("@userId");
				await localStorage.removeItem("@RefreshToken");
				await localStorage.removeItem("@TokenExpiration");
				await localStorage.removeItem("@FirstName");
				await localStorage.removeItem("@LastName");
				store.dispatch(push("/Logout"));
			});
	}
	
	var client = axios.create({
		baseURL: Environment.baseApiUrl,
		headers: { Authorization: "Bearer " + token, 'X-ClientId': Environment.clientId },
	});
	client.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			//console.log("Successful Interceptor", response);
			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			console.log("Error Interceptor", error);
			return Promise.reject(error);
		}
	);

	return client;
};

export default publicClient;
export { authenticatedClient };
