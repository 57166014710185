import React from "react";
import { Route, Switch } from "react-router-dom";

import GroupSearch from "../pages/groups/group-search";
import GroupDetail from "../pages/groups/group-detail";

const GroupsRouter = (props) => {
	
	let results = (
		<React.Fragment>
			<Switch>
				<Route exact path="/groups/:groupId" component={GroupDetail} />
				<Route exact path="/groups" component={GroupSearch} />
			</Switch>
		</React.Fragment>
	);

	return results;
};

export default GroupsRouter;
