import { call, put, takeLatest } from "redux-saga/effects";

import { authenticatedClient } from "../../services/api-service";
import * as UsersConstants from "../actions/constants/users";

const buildLoadUsersRequest = async () => {
	const client = await authenticatedClient();

	return client
		.get('/users', {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}

			return error;
		});
}

const buildSaveUserRequest = async (userId, userItem) => {
	const client = await authenticatedClient();

	if (userId && userId.length > 0) {
		return client
			.put(`/users/${userId}`, userItem, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw response;
				}
				return response;
			})
			.catch((error) => {
				if (error?.response?.status) {
					return error.response;
				}
				
				return error;
			});
	} else {
		return client
			.post('/users', userItem, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw response;
				}
				return response;
			})
			.catch((error) => {
				if (error?.response?.status) {
					return error.response;
				}
				
				return error;
			});
	}
}

const buildDeleteUserRequest = async (userId) => {
	const client = await authenticatedClient();

	return client
		.delete(`/users/${userId}`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

function* LoadUsersSaga(action) {
	try {
		const response = yield call(buildLoadUsersRequest);

		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		yield put({
			type: UsersConstants.LOAD_USERS_SUCCEEDED,
			users: response.data,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: UsersConstants.LOAD_USERS_FAILED,
			error: error,
		});
	}
}

function* SaveUserSaga(action) {
	try {
		const response = yield call(buildSaveUserRequest, action.userId, action.userItem);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}

		alert('User successfully saved!');
		
		yield put({
			type: UsersConstants.SAVE_USER_SUCCEEDED,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: UsersConstants.SAVE_USER_FAILED,
			error: error,
		});
	}
}

function* DeleteUserSaga(action) {
	try {
		const response = yield call(buildDeleteUserRequest, action.userId);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}

		alert('User deleted!');
		
		yield put({
			type: UsersConstants.DELETE_USER_SUCCEEDED,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: UsersConstants.DELETE_USER_FAILED,
			error: error,
		});
	}
}

export default function* usersSagas() {
	//Take Latest
	yield takeLatest(UsersConstants.LOAD_USERS_REQUESTED, LoadUsersSaga);
	yield takeLatest(UsersConstants.SAVE_USER_REQUESTED, SaveUserSaga);
	yield takeLatest(UsersConstants.DELETE_USER_REQUESTED, DeleteUserSaga);
}
