import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Alert } from "react-bootstrap";

import CustomSpinner from "../../components/custom-spinner";
import TextBox from "../../components/text-box";
import CustomButton from "../../components/custom-button";
import { ClearUserSaveErrorCreator, UserDeleteCreator, UserSaveCreator, UsersLoadCreator } from "../../redux/actions/creators/users";
import CustomSwitch from "../../components/custom-switch";

const Users = (props) => {
	const [selectedUserId, setSelectedUserId] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	const [isPushUser, setIsPushUser] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isPasswordError, setIsPasswordError] = useState(false);

	const selectUser = (id) => {
		// selecting already selected id
		if (id === selectedUserId) {
			return;
		}

		if (props.isSaving || props.isLoadingUsers) {
			return;
		}

		props.ClearSaveError();

		const index = props.users.findIndex(x => x.userId === id);
		if (index >= 0) {
			setSelectedUserId(id);
			setEmail(props.users[index].emailAddress);
			setFirstName(props.users[index].firstName);
			setLastName(props.users[index].lastName);
			setPassword('');
			setConfirmPassword('');
			setIsAdmin(props.users[index].isAdmin);
			setIsPushUser(props.users[index].isPushUser);
		} else {
			setSelectedUserId('');
			setEmail('');
			setFirstName('');
			setLastName('');
			setPassword('');
			setConfirmPassword('');
			setIsAdmin(false);
			setIsPushUser(false);
		}
	};

	const handleSave = () => {
		if (props.isLoadingUsers || props.isSaving) {
			// already loading, return
			return;
		}

		if (email.trim().length === 0) {
			setErrorMessage('Invalid Email.');
			return;
		}

		if ((email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/ig) || []).length === 0) {
			setErrorMessage('Invalid Email.');
			return;
		}

		if (firstName.trim().length === 0) {
			setErrorMessage('Invalid First Name.');
			return;
		}

		if (lastName.trim().length === 0) {
			setErrorMessage('Invalid Last Name.');
			return;
		}

		const pass = password;
		if (selectedUserId === '') {
			if (password.length < 6) {
				setErrorMessage('Password must be at least 6 characters.');
				setIsPasswordError(true);
				return;
			}

			if ((password.match(/[0-9]+/ig) || []).length === 0) {
				setErrorMessage('Password must have at least 1 numeric character (0-9).');
				setIsPasswordError(true);
				return;
			}

			if ((password.match(/[A-Z]+/g) || []).length === 0) {
				setErrorMessage('Password must have at least 1 uppercase character.');
				setIsPasswordError(true);
				return;
			}

			if ((password.match(/[a-z]+/g) || []).length === 0) {
				setErrorMessage('Password must have at least 1 lowercase character.');
				setIsPasswordError(true);
				return;
			}

			if ((password.match(/[^A-Z^0-9]+/ig) || []).length === 0) {
				setErrorMessage('Password must have at least 1 non-alphanumeric character.');
				setIsPasswordError(true);
				return;
			}

			if (password !== confirmPassword) {
				setErrorMessage('Password and Confirm Password must match.');
				setIsPasswordError(true);
				return;
			}
		} else {
			if (password && password !== '' && password !== confirmPassword) {
				setErrorMessage('Password and Confirm Password must match.');
				setIsPasswordError(true);
				return;
			}
		}

		setErrorMessage('');
		setIsPasswordError(false);

		const userItem = {
			emailAddress: email,
			firstName: firstName,
			lastName: lastName,
			password: pass,
			isPushUser: isPushUser,
			isAdmin: isAdmin
		};

		props.SaveUser(selectedUserId, userItem);
	};

	const handleDelete = () => {
		if (!selectedUserId || selectedUserId.length === 0) {
			return;
		}

		const isConfirmed = window.confirm('Are you sure you wish to delete this user? This cannot be undone.');
		if (isConfirmed) {
			props.DeleteUser(selectedUserId);
			return;
		}
	};
	
	useEffect(() => {
		if (!props.isSaving && !props.saveError) {
			props.LoadUsers();
			setSelectedUserId('');
			setEmail('');
			setFirstName('');
			setLastName('');
			setPassword('');
			setConfirmPassword('');
			setIsAdmin(false);
			setIsPushUser(false);
		}
	}, [props.isSaving]);

	let error = null;
	if (errorMessage != "") {
		error = <Alert variant={"danger"}>{errorMessage}</Alert>;
	}

	let passwordError = null;
	if (isPasswordError) {
		const minReq = (
			<>
				<span>Password Requirements</span>
				<ul>
					<li>Minimum length: 6</li>
					<li>At least 1 uppercase alpha character (A-Z).</li>
					<li>At least 1 lowercase alpha character (a-z).</li>
					<li>At least 1 numeric character (0-9).</li>
					<li>At least 1 non-alphanumeric character.</li>
				</ul>
			</>
		);
		passwordError = <Alert variant={"warning"}>{minReq}</Alert>;
	}

	let saveErr = null;
	if (props.saveError) {
		let saveErrMsg = props.saveError;
		const matches = saveErrMsg.match(/(?:Exception: (?:\[[^\]]*\] )?)([^\n]+)(?:\n)/);
		if ((matches || []).length > 1) {
			saveErrMsg = matches[1];
		}

		saveErr = <Alert variant={"danger"}>Error saving user: {saveErrMsg}</Alert>;
	}

	return (
			<>
				<h1>Users</h1>
				<br />
				<Row id="wrapper">
					<Col id="groupList" data-qa="groupList" md={4}>
						<h3>Select User:</h3>
						{ props.isLoadingUsers && <CustomSpinner />}
						{ props.users &&
							<div
								onClick={() => selectUser('')}
								className={`user-item${selectedUserId === '' ? ' selected-item' : ''}`}
							>Create New User</div>
						}
						{ props.users &&
							props.users
								.sort((a,b) => a.lastName.localeCompare(b.lastName))
								.map(
									user => {
										let adminTag = null;
										if (user.isAdmin) {
											adminTag = <span className="admin-tag">[Admin]</span>;
										}

										let pushTag = null;
										if (user.isPushUser) {
											pushTag = <span className="push-tag">[Push]</span>;
										}

										return (
											<div
												key={user.userId}
												onClick={() => selectUser(user.userId)}
												className={`user-item${selectedUserId === user.userId ? ' selected-item' : ''}`}
											><span>{user.lastName}, {user.firstName}</span>{adminTag}{pushTag}</div>
										);
									}
								)
						}
					</Col>
					<Col id="message" md={8}>
						{error}
						{ !props.isLoadingUsers &&
							<>
								<TextBox
									Label={"Email"}
									type={"email"}
									value={email}
									onChange={(value) => setEmail(value)}
								/>
								<br />
								<TextBox
									Label={"First Name"}
									type={"text"}
									value={firstName}
									onChange={(value) => setFirstName(value)}
								/>
								<br />
								<TextBox
									Label={"Last Name"}
									type={"text"}
									value={lastName}
									onChange={(value) => setLastName(value)}
								/>
								<br />
								<TextBox
									Label={"Password"}
									placeholder={"Leave blank to not change...."}
									type={"password"}
									value={password}
									onChange={(value) => setPassword(value)}
								/>
								{passwordError}
								<TextBox
									Label={"Confirm Password"}
									placeholder={""}
									type={"password"}
									value={confirmPassword}
									onChange={(value) => setConfirmPassword(value)}
								/>
								<br />

								<CustomSwitch onChange={() => setIsPushUser(!isPushUser)} checked={isPushUser} Label={"Can Send Push Notifications"} />
								
								<CustomSwitch onChange={() => setIsAdmin(!isAdmin)} checked={isAdmin} Label={"User Administrator"} />
								
								<br />
								<div className="form-buttons">
									<CustomButton text={"Save"} disabled={props.isSaving} isLoadingButton={props.isSaving} onClick={() => handleSave()} />
									{ selectedUserId !== '' && <CustomButton text={"Delete User"} variant="outline-danger" disabled={props.isSaving} isLoadingButton={props.isSaving} onClick={() => handleDelete()} /> }
								</div>
								
								<br />
								<br />
								{saveErr}
							</>
						}
					</Col>
				</Row>
				
			</>
	);
};

export const MapStateToProps = (state) => {
	return {
		isLoadingUsers: state.users.isLoading,
		users: state.users.users,
		isSaving: state.users.isSaving,
		saveError: state.users.saveError
	};
};

export const MapDispatchToProps = (dispatch) => {
	return {
		LoadUsers: () => dispatch(UsersLoadCreator()),
		SaveUser: (userId, userItem) => dispatch(UserSaveCreator(userId, userItem)),
		ClearSaveError: () => dispatch(ClearUserSaveErrorCreator()),
		DeleteUser: (userId) => dispatch(UserDeleteCreator(userId))
	};
};

export default connect(MapStateToProps, MapDispatchToProps)(Users);
