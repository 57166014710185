import React from "react";
import Environment from "../env/environment";

const Title = (props) => {

	return (
		<div id="title">
			<span className="text">{Environment.clientId === 1 ? 'My Benefits Aide Manager' : 'FBS App Manager'}</span>
		</div>
	);
};

export default Title;
