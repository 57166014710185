import * as UserConstants from "../actions/constants/users";

const initalState = {
	isLoading: false,
	users: [],
	isSaving: false,
	saveError: ''
};

export const users = (state = initalState, action) => {
	switch (action.type) {
		case UserConstants.LOAD_USERS_REQUESTED:
			return {
				...state,
				isLoading: true
			};
		case UserConstants.LOAD_USERS_SUCCEEDED:
			return {
				...state,
				isLoading: false,
				users: action.users
			};
		case UserConstants.LOAD_USERS_FAILED:
			return {
				...state,
				isLoading: false,
				users: []
			};
		case UserConstants.SAVE_USER_REQUESTED:
			return {
				...state,
				isSaving: true
			};
		case UserConstants.SAVE_USER_SUCCEEDED:
			return {
				...state,
				isSaving: false,
				saveError: ''
			};
		case UserConstants.SAVE_USER_FAILED:
			return {
				...state,
				isSaving: false,
				saveError: action.error
			};
		case UserConstants.CLEAR_USER_SAVE_ERROR:
			return {
				...state,
				isSaving: false,
				saveError: ''
			};
		case UserConstants.DELETE_USER_REQUESTED:
			return {
				...state,
				isSaving: true
			};
		case UserConstants.DELETE_USER_SUCCEEDED:
			return {
				...state,
				isSaving: false,
				saveError: ''
			};
		case UserConstants.DELETE_USER_FAILED:
			return {
				...state,
				isSaving: false,
				saveError: action.error
			};
		default:
			return state;
	}
};
