import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { GroupLoadActionCreator, GroupSaveActionCreator } from "../../redux/actions/creators/groups";
import TextBox from "../../components/text-box";
import CustomButton from "../../components/custom-button";
import CustomSwitch from "../../components/custom-switch";
import CustomSpinner from "../../components/custom-spinner";

const GroupDetail = (props) => {
	const params = useParams();

	useEffect(() => {
		if (params.groupId && params.groupId > 0) {
			props.LoadGroup(params.groupId);
		}
	}, [params.groupId]);

	const [groupCode, setGroupCode] = useState(props.group?.groupCode ?? '');
	const [isAppActive, setIsAppActive] = useState(props.group?.isAppActive ?? false);
	const [isNameVisible, setIsNameVisible] = useState(props.group?.isGroupNameVisible ?? true);
	const [isEnrollmentVisible, setIsEnrollmentVisible] = useState(props.group?.isEnrollmentButtonVisible ?? true);
	const [isClientWebsiteVisible, setIsClientWebsiteVisible] = useState(props.group?.isClientWebsiteButtonVisible ?? true);
	const [enrollmentUrlOverride, setEnrollmentUrlOverride] = useState(props.group?.enrollmentUrlOverride ?? '');
	const [validationMessage, setValidationMessage] = useState('');
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		setGroupCode(props.group?.groupCode ?? '');
		setIsAppActive(props.group?.isAppActive ?? false);
		setIsNameVisible(props.group?.isGroupNameVisible ?? true);
		setIsEnrollmentVisible(props.group?.isEnrollmentButtonVisible ?? true);
		setIsClientWebsiteVisible(props.group?.isClientWebsiteButtonVisible ?? true);
		setEnrollmentUrlOverride(props.group?.enrollmentUrlOverride ?? '');
	}, [props.group]);

	const handleSubmit = () => {

		if ((groupCode.match(/^[a-zA-Z0-9]{3,20}$/g) || []).length === 0) {
			setIsValid(false);
			setValidationMessage('Invalid group code. Value must be between 3 and 20 alpha-numeric characters.');
			return;
		}

		if (isEnrollmentVisible) {
			if (enrollmentUrlOverride.length > 0 && (enrollmentUrlOverride.match(/^((https?):\/\/)([a-z0-9]+\.)+[a-z]+(\/[a-z0-9#\.]+\/?)*$/g) || []).length === 0) {
				setIsValid(false);
				setValidationMessage('Invalid enrollment url. Please enter valid url, including http/https.');
				return;
			}
		}

		const groupSettings = {
			groupId: props.group?.groupId ?? 0,
			nexusCompanyId: props.group?.nexusCompanyId ?? 0,
			nexusGroupId: +params?.groupId ?? 0,
			groupCode: groupCode,
			isAppActive: isAppActive,
			isGroupNameVisible: isNameVisible,
			isEnrollmentVisible: isEnrollmentVisible,
			enrollmentUrlOverride: isEnrollmentVisible ? enrollmentUrlOverride : (props.group?.enrollmentUrlOverride ?? ''),
			isClientWebsiteVisible: isClientWebsiteVisible
		};

		props.SaveGroup(groupSettings);
		
	};

	let validation = null;
	if (!isValid) {
		validation = <Alert variant={"danger"}>{validationMessage}</Alert>;
	}
	
	return (
			<div className="group-detail-container">
				<h1>{ props.group?.shortName }</h1>
				{ props.isLoading ||
					<>
						<img className="logo-preview" src={props.group?.logoUrl ?? "#"} />
						{validation}
						<TextBox
							Label={"Group Code"}
							type={"text"}
							value={groupCode ?? ''}
							onChange={(value) => setGroupCode((value || '').trim())}
						/>
						<br />
						<CustomSwitch onChange={() => setIsAppActive(!isAppActive)} checked={isAppActive} Label={"App Enabled"} />
						<br />
						<CustomSwitch onChange={() => setIsNameVisible(!isNameVisible)} checked={isNameVisible} Label={"Show Group Name"} />
						<br />
						<CustomSwitch onChange={() => setIsEnrollmentVisible(!isEnrollmentVisible)} checked={isEnrollmentVisible} Label={"Show Enrollment Button"} />
						<br />
						{ isEnrollmentVisible ?
							<>
								<TextBox
									Label={"Enrollment Url (include http:/https:)"}
									placeholder={props.group?.enrollmentUrl || 'Leave blank for Hub Login page'}
									type={"text"}
									value={enrollmentUrlOverride ?? ''}
									onChange={(value) => setEnrollmentUrlOverride((value || '').trim().toLowerCase())}
									className={"enrollment-url"}
								/>
								<br />
							</>
							: null
						}
						<CustomSwitch onChange={() => setIsClientWebsiteVisible(!isClientWebsiteVisible)} checked={isClientWebsiteVisible} Label={"Show Client Website Button"} />
						<label>{isClientWebsiteVisible ? props.group?.clientWebsiteUrl : null}</label>
						<br />
						<CustomButton text={"Save"} onClick={() => handleSubmit()} />
					</>
				}
				{ props.isLoading && 
					<>
						<h2>Loading... <CustomSpinner /></h2>
					</>
				}
			</div>
	);
};

export const MapStateToProps = (state) => {
	return {
		group: state.groups.group,
		isLoading: state.groups.isLoading
	};
};

export const MapDispatchToProps = (dispatch) => {
	return {
		LoadGroup: (groupId) => dispatch(GroupLoadActionCreator(groupId)),
		SaveGroup: (groupData) => dispatch(GroupSaveActionCreator(groupData))
	};
};

export default connect(MapStateToProps, MapDispatchToProps)(GroupDetail);
