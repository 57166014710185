import { call, put, takeLatest } from "redux-saga/effects";

import { authenticatedClient } from "../../services/api-service";
import * as NotificationConstants from "../actions/constants/notifications";

const buildSendNotificationRequest = async (payload) => {
	const client = await authenticatedClient();

	return client
		.post("/pushnotifications", payload, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

function* SendPushNotificationSaga(action) {
	try {
		const response = yield call(buildSendNotificationRequest, action.notification);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		alert('Push notification has successfully been queued!');

		yield put({
			type: NotificationConstants.NOTIFICATION_SEND_SUCCEEDED,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: NotificationConstants.NOTIFICATION_SEND_FAILED,
			error: error,
		});
	}
}

export default function* notificationsSagas() {
	//Take Latest
	yield takeLatest(NotificationConstants.NOTIFICATION_SEND_REQUESTED, SendPushNotificationSaga);
}
