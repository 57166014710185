import React from "react";
import Button from "react-bootstrap/Button";
import CustomSpinner from "./custom-spinner";

const CustomButton = (props) => {
	let className = props.className ?? 'button';
	if (props.isLoadingButton) {
		className += ' btn-with-loading';
	}
	return (
		<Button
			variant={props.variant ?? "outline-primary"}
			onClick={props.onClick}
			className={className}
			disabled={props.disabled}
		>
			{props.text}
			{ props.isLoadingButton && <CustomSpinner /> }
		</Button>
	);
};

export default CustomButton;
