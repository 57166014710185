import React, { useEffect } from "react";
import { connect } from "react-redux";

import MainRouter from "../routing/main-router";
import { NavigateAction } from "../redux/actions/creators/navigation";


const LoginLayout = (props) => {
	
	useEffect(() => {
		const token = localStorage.getItem("@Token");
		if (token != null && token.length > 0) {
			props.navigate("/");
		}
	});

	return (
		<>
			<MainRouter isAuthenticated={props.isAuthenticated} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.authentication.isAuthenticated,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigate: (path) => dispatch(NavigateAction(path)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
