import React from "react";
import Form from "react-bootstrap/Form";

const TextBox = (props) => {
	return (
		<>
			<Form.Group>
				<Form.Label>{props.Label}</Form.Label>
				<Form.Control
					type={props.type}
					as={props.type === "textarea" ? "textarea" : "input"}
					rows={props.rows || '1'}
					placeholder={props.placeholder}
					onChange={(e) => props.onChange(e.target.value)}
					// defaultValue={props.value}
					value={props.value}
					className={props.className}
				/>
				<Form.Text className="text-muted">{props.mutedText}</Form.Text>
			</Form.Group>
		</>
	);
};
export default TextBox;
