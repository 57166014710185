import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { authentication } from "./authentication";
import { users } from "./users";
import { groups } from "./groups";
import { notifications } from "./notifications";
import { news } from "./news";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authentication: authentication,
	users: users,
	groups: groups,
	notifications: notifications,
	news: news
  });

export default rootReducer;
