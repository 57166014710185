export const GROUP_SEARCH_REQUESTED = "GROUP_SEARCH_REQUESTED";
export const GROUP_SEARCH_SUCCEEDED = "GROUP_SEARCH_SUCCEEDED";
export const GROUP_SEARCH_FAILED = "GROUP_SEARCH_FAILED";
export const GROUP_LOAD_REQUESTED = "GROUP_LOAD_REQUESTED";
export const GROUP_LOAD_SUCCEEDED = "GROUP_LOAD_SUCCEEDED";
export const GROUP_LOAD_FAILED = "GROUP_LOAD_FAILED";
export const GROUP_SAVE_REQUESTED = "GROUP_SAVE_REQUESTED";
export const GROUP_SAVE_SUCCEEDED = "GROUP_SAVE_SUCCEEDED";
export const GROUP_SAVE_FAILED = "GROUP_SAVE_FAILED";
export const GROUP_LOAD_ACTIVE_REQUESTED = "GROUP_LOAD_ACTIVE_REQUESTED";
export const GROUP_LOAD_ACTIVE_SUCCEEDED = "GROUP_LOAD_ACTIVE_SUCCEEDED";
export const GROUP_LOAD_ACTIVE_FAILED = "GROUP_LOAD_ACTIVE_FAILED";
export const DOWNLOAD_DEVICES_REPORT_REQUESTED = "DOWNLOAD_DEVICES_REPORT_REQUESTED";
export const DOWNLOAD_DEVICES_REPORT_SUCCEEDED = "DOWNLOAD_DEVICES_REPORT_SUCCEEDED";
export const DOWNLOAD_DEVICES_REPORT_FAILED = "DOWNLOAD_DEVICES_REPORT_FAILED";