import * as AuthenticationConstants from "../constants/authentication";

export const LoginActionCreator = (username, password) => {
	return {
		type: AuthenticationConstants.LOGIN_REQUESTED,
		username: username,
		password: password,
	};
};

export const LogoutActionCreator = () => {
	return {
		type: AuthenticationConstants.LOGOUT
	};
};

export const ClearStateCreator = () => {
	return {
		type: AuthenticationConstants.CLEAR_STATE,
	};
};
