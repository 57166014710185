import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Alert } from "react-bootstrap";

import CustomSpinner from "../../components/custom-spinner";
import TextBox from "../../components/text-box";
import CustomButton from "../../components/custom-button";
import { NewsLoadCreator, NewsSaveCreator } from "../../redux/actions/creators/news";
import { GroupLoadActiveActionCreator } from "../../redux/actions/creators/groups";

const News = (props) => {
	const [selectedNewsItemId, setSelectedNewsItemId] = useState(0);
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [postDate, setPostDate] = useState(null);
	const [groupIds, setGroupIds] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');

	const selectArticle = (id) => {
		if (id === selectedNewsItemId) {
			return;
		}

		if (props.isSaving || props.isLoadingNews) {
			return;
		}

		const index = props.newsItems.findIndex(x => x.id === id);
		if (index >= 0) {
			setSelectedNewsItemId(id);
			setTitle(props.newsItems[index].title);
			setBody(props.newsItems[index].body);
			setPostDate(new Date(props.newsItems[index].dateUtc));
			setGroupIds(props.newsItems[index].groupIds);
		} else {
			setSelectedNewsItemId(0);
			setTitle('');
			setBody('');
			setPostDate(null);
			setGroupIds([]);
		}
	};

	const handleSave = () => {
		if (props.isLoadingNews || props.isLoadingGroups || props.isSaving) {
			// already loading, return
			return;
		}

		if (title.trim().length === 0) {
			setErrorMessage('Invalid Title.');
			return;
		}

		if (body.trim().length === 0) {
			setErrorMessage('Invalid Body.');
			return;
		}

		if (!postDate || postDate == NaN) {
			setErrorMessage('Invalid Post Date.');
			return;
		}

		setErrorMessage('');

		const newsItem = {
			id: selectedNewsItemId,
			title: title,
			body: body,
			dateUtc: postDate.toISOString(),
			groupIds: groupIds
		};
		
		props.SaveNewsItem(newsItem);
	};

	const toggleAllGroups = () => {
		if (groupIds.length === props.groups.length) {
			setGroupIds([]);
		} else {
			setGroupIds([...props.groups.map(x => x.groupId)]);
		}
	};

	const toggleGroupSelection = (groupId) => {
		const index = groupIds.findIndex(x => x === groupId);
		if (index < 0) {
			setGroupIds([...groupIds, groupId]);
		} else {
			let groups = [...groupIds];
			groups.splice(index, 1);

			setGroupIds(groups);
		}
	};
	
	useEffect(() => {
		if (!props.isSaving) {
			props.LoadNewsItems();
			props.LoadGroups();
			setSelectedNewsItemId(0);
			setTitle('');
			setBody('');
			setPostDate(null);
			setGroupIds([]);
		}
	}, [props.isSaving]);

	let error = null;
	if (errorMessage != "") {
		error = <Alert variant={"danger"}>{errorMessage}</Alert>;
	}

	return (
			<>
				<h1>News Articles</h1>
				<br />
				<Row id="wrapper">
					<Col id="newsList" data-qa="groupList" md={4}>
						<h3>Select Article:</h3>
						{ (props.isLoadingNews || props.isLoadingGroups) && <CustomSpinner />}
						{ !props.isLoadingNews && !props.isLoadingGroups &&
						<div style={{ maxHeight: "80%", overflowY: "scroll" }}>
							<div
								onClick={() => selectArticle(0)}
								className={`news-item${selectedNewsItemId === 0 ? ' selected-item' : ''}`}
							>Create New Article</div>
							{ props.newsItems
								.sort((a,b) => (new Date(b.dateUtc)) - (new Date(a.dateUtc)))
								.map(
									newsItem => {
										const dt = new Date(newsItem.dateUtc);
										const today = new Date();
										const yearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());

										let tag = null;
										if (dt > today) {
											tag = <span className="future-tag">[Future]</span>;
										}
										if (dt < yearAgo) {
											tag = <span className="expired-tag">[Expired]</span>;
										}


										return (
											<div
												key={newsItem.id}
												onClick={() => selectArticle(newsItem.id)}
												className={`news-item${selectedNewsItemId === newsItem.id ? ' selected-item' : ''}`}
											><span>{newsItem.title} - [{dt.getMonth()+1}/{dt.getDate()}/{dt.getFullYear()}]</span>{tag}</div>
										);
									}
								)
							}
						</div> }
					</Col>
					<Col id="message" md={4}>
						{ !props.isLoadingNews &&
							<>
								<TextBox
									Label={"Title"}
									type={"text"}
									value={title}
									onChange={(value) => setTitle(value)}
								/>
								<TextBox
									Label={"Body"}
									type={"textarea"}
									rows={'15'}
									value={body}
									onChange={(value) => setBody(value)}
								/>
								<TextBox
									Label={"Post Date"}
									type={"datetime-local"}
									value={postDate ? `${postDate.getFullYear()}-${(postDate.getMonth()+1).toString().padStart(2, '0')}-${(postDate.getDate()).toString().padStart(2, '0')}T${postDate.getHours().toString().padStart(2, '0')}:${postDate.getMinutes().toString().padStart(2, '0')}` : ''}
									onChange={(value) => setPostDate(new Date(value))}
								/>
								<CustomButton text={"Save"} disabled={props.isSaving} isLoadingButton={props.isSaving} onClick={() => handleSave()} />
								{error}
							</>
						}
					</Col>
					<Col id="groupList" data-qa="groupList" md={4}>
						<h3>Select Group(s):</h3>
						{ props.isLoadingGroups && <CustomSpinner />}
						{ !props.isLoadingGroups &&
						<div style={{ maxHeight: "80%", overflowY: "scroll" }}>
							<div onClick={() => toggleAllGroups()} className={`news-group${groupIds.length === props.groups.length ? ' selected-group' : ''}`}>All Groups</div>
							{ props.groups.map(
									group => (
										<div
											key={group.groupId}
											onClick={() => toggleGroupSelection(group.groupId)}
											className={`news-group${groupIds.includes(group.groupId) ? ' selected-group' : ''}`}
										>{group.groupName}</div>
									)
								)
							}
						</div> }
					</Col>
				</Row>
				
			</>
	);
};

export const MapStateToProps = (state) => {
	return {
		groups: state.groups.activeGroups,
		isLoadingGroups: state.groups.isLoading,
		isLoadingNews: state.news.isLoading,
		newsItems: state.news.newsItems,
		isSaving: state.news.isSaving
	};
};

export const MapDispatchToProps = (dispatch) => {
	return {
		LoadNewsItems: () => dispatch(NewsLoadCreator(false)),
		LoadGroups: () => dispatch(GroupLoadActiveActionCreator()),
		SaveNewsItem: (newsItem) => dispatch(NewsSaveCreator(newsItem))
	};
};

export default connect(MapStateToProps, MapDispatchToProps)(News);
