import React, { useState } from "react";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import { connect } from "react-redux";
import { NavigateAction } from "../redux/actions/creators/navigation";
import Title from "../components/title";
//import ProfileIcon from "../custom-components/profile-icon/profile-icon";

const HeaderLayout = (props) => {
	const [isExpanded, updateIsExpanded] = useState(false);

	let navLinks = [
		{ title: "Home", link: "/" },
		{ title: "Groups", link: "/groups" },
		{ title: "News", link: "/news" },
		{ title: "Notifications", link: "/notifications" },
		{ title: "Users", link: "/users" },
		{ title: "Logout", link: "/logout" },
	];

	if (!props.isAuthenticated) {
		navLinks = [
			{ title: "Login", link: "/Login" },
			//{ title: "Register", link: "/Register" },
		];
	}

	const HandleNavigation = (destination) => {
		updateIsExpanded(!isExpanded);
		props.navigate(destination);
	};

	return (
		<>
			<Navbar
				expand="med"
				id="MainNavigation"
				expanded={isExpanded}
				onToggle={() => updateIsExpanded(!isExpanded)}
			>
				<Navbar.Brand className={"order-1 pull-left"}>
					<div className="BrandWrapper">
						<Title />
						{/* <ProfileIcon isLoggedIn={props.isAuthenticated} /> */}
					</div>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="MainNavigationItems" />
				<Navbar.Collapse
					id="MainNavigationItems"
					className={"order-0"}
				>
					{navLinks.map((link) => {
						if (link.title === "Notifications" && !props.isPushUser) {
							return null;
						}
						if (link.title === "Users" && !props.isAdmin) {
							return null;
						}

						return (
							<Nav.Link
								key={link.title}
								onClick={() => HandleNavigation(link.link)}
							>
								{link.title}
							</Nav.Link>
						);
					})}
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigate: (location) => dispatch(NavigateAction(location)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
