import React, { useState } from "react";
import { connect } from "react-redux";
import { Accordion, Nav } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";

import LockIcon from "mdi-react/LockIcon";
import CogIcon from "mdi-react/CogIcon";
import HomeIcon from "mdi-react/HomeIcon";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon";
import InformationIcon from "mdi-react/InformationIcon";
import PollboxIcon from "mdi-react/PollBoxIcon";
import NewspaperVariantOutlineIcon from "mdi-react/NewspaperVariantOutlineIcon";
import MessageAlertIcon from "mdi-react/MessageAlertIcon";
import ExitToAppIcon from "mdi-react/ExitToAppIcon";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";

import { DownloadDevicesReportActionCreator } from "../redux/actions/creators/groups";

const SideLayout = (props) => {
	
	let links = [
		{
			displayName: "Login",
			isHeader: false,
			iconName: "lock",
			navigation: "/Login",
			key: "LOGIN",
		},
	];
	if (props.isAuthenticated) {
		links = [
			{
				displayName: "Home",
				isHeader: false,
				iconName: "home",
				navigation: "/",
				key: "HOME",
			},
			{
				displayName: "Groups",
				isHeader: false,
				iconName: "uiradios",
				navigation: "/groups",
				key: "GROUPS",
			},
			{
				displayName: "News",
				isHeader: false,
				iconName: "news",
				navigation: "/news",
				key: "NEWS",
			},
			{
				displayName: "Push",
				isHeader: false,
				iconName: "notifications",
				navigation: "/notifications",
				key: "NOTIFICATIONS",
			},
			{
				displayName: "Users",
				isHeader: false,
				iconName: "settings",
				navigation: "/users",
				key: "USERS",
			}
		];
	}

	const defaultActiveKey = links[0].key;

	const [activeLink, setActiveLink] = useState(defaultActiveKey);

	const downloadDevicesReportHandler = () => {
		props.DownloadReport();
	}

	if (!props.isAuthenticated) {
		return null;
	}

	return (
		<React.Fragment>
			<Accordion defaultActiveKey={defaultActiveKey}>
				<Nav activeKey={activeLink} className="flex-column">
					{links.map((link) => {

						if (link.key === "NOTIFICATIONS" && !props.isPushUser) {
							return null;
						}

						if (link.key === "USERS" && !props.isAdmin) {
							return null;
						}
						
						let icon = null;
						if ("iconName" in link) {
							if (link.iconName === "information") {
								icon = <InformationIcon className="aside-icon" />;
							} else if (link.iconName === "home") {
								icon = <HomeIcon className="aside-icon" />;
							} else if (link.iconName === "uiradios") {
								icon = <FormatListBulletedIcon className="aside-icon" />;
							} else if (link.iconName === "lock") {
								icon = <LockIcon className="aside-icon" />;
							} else if (link.iconName === "report") {
								icon = <PollboxIcon className="aside-icon" />;
							} else if (link.iconName === "settings") {
								icon = <CogIcon className="aside-icon" />;
							} else if (link.iconName == "news") {
								icon = <NewspaperVariantOutlineIcon className="aside-icon" />;
							} else if (link.iconName == "notifications") {
								icon = <MessageAlertIcon className="aside-icon" />
							}
						}

						return (
							<Accordion.Toggle
								as={Nav.Item}
								eventKey={link.key}
								key={link.key}
								onClick={() => setActiveLink(link.key)}
							>
								<IndexLinkContainer to={link.navigation}>
									<Nav.Link
										className="nav-link-green"
										data-qa="dashboardNavLink"
									>
										{icon}
										<br />
										{link.displayName}
									</Nav.Link>
								</IndexLinkContainer>
							</Accordion.Toggle>
						);
					})}
				</Nav>
			</Accordion>

			{ props.isAuthenticated ?
				<>
					<Nav.Link onClick={() => downloadDevicesReportHandler()}
						className="nav-link-green"
						data-qa="dashboardNavLink"
					>
						<CloudDownloadIcon className="aside-icon" />
						<br />
						Devices
					</Nav.Link>

					<Accordion.Toggle
						as={Nav.Item}
						eventKey={"LOGOUT"}
						key={"LOGOUT"}
						onClick={() => setActiveLink("LOGOUT")}
						>
						<IndexLinkContainer to={"/logout"}>
							<Nav.Link
								className="nav-link-green"
								data-qa="dashboardNavLink"
							>
								<ExitToAppIcon className="aside-icon" />
								<br />
								Log Out
							</Nav.Link>
						</IndexLinkContainer>
					</Accordion.Toggle>
				</>
			: null }
		</React.Fragment>
	);
};

export const MapDispatchToProps = (dispatch) => {
	return {
		DownloadReport: () => dispatch(DownloadDevicesReportActionCreator()),
	};
};

export default connect(null, MapDispatchToProps)(SideLayout);
