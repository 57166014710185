import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomSpinner = (props) => {
    return (
        <div className="spinner-wrapper">
            <Spinner className="spinner-border-custom" animation="border" />
        </div>
    );
}

export default CustomSpinner; 