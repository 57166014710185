import * as UserConstants from "../constants/users";

export const UsersLoadCreator = () => {
	return {
		type: UserConstants.LOAD_USERS_REQUESTED
	};
};

export const UserSaveCreator = (userId, userItem) => {
	return {
		type: UserConstants.SAVE_USER_REQUESTED,
		userId: userId,
		userItem: userItem
	};
};

export const UserDeleteCreator = (userId) => {
	return {
		type: UserConstants.DELETE_USER_REQUESTED,
		userId: userId
	};
};

export const ClearUserSaveErrorCreator = () => {
	return {
		type: UserConstants.CLEAR_USER_SAVE_ERROR
	};
};