import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import TextBox from "../../components/text-box";
import CustomButton from "../../components/custom-button";
import { GroupsSearchActionCreator } from "../../redux/actions/creators/groups";

const GroupSearch = (props) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const handleSearch = () => {
		if (props.isSearching) {
			// already searching, return
			return;
		}

		if (searchTerm.trim().length === 0) {
			setErrorMessage('Invalid search term.');
			return;
		}
		setErrorMessage('');

		props.SearchGroups(searchTerm);
	};

	let error = null;
	if (errorMessage != "") {
		error = <Alert variant={"danger"}>{errorMessage}</Alert>;
	}

	let groupResults = null;
	if (props.searchResults && props.searchResults.length > 0) {
		groupResults = props.searchResults.sort((a,b) => a.groupName.localeCompare(b.groupName)).map(x => <li key={x.nexusGroupId}><Link to={'/groups/' + x.nexusGroupId}>{x.groupName}</Link></li>);
	}
	
	return (
			<>
				<h1>Group Search</h1>
				<div className={"report-box"}>
					<CustomButton text={"Devices Report"} disabled={props.isSearching} isLoadingButton={props.isSearching} onClick={() => alert('hi?')} />
				</div>
				<div className={"search-box"}>
					<TextBox
						placeholder={"Group Name"}
						onChange={(value) => setSearchTerm(value)}
					/>
					<CustomButton text={"Search"} disabled={props.isSearching} isLoadingButton={props.isSearching} onClick={() => handleSearch()} />
					{error}
				</div>
				<br />
				<br />
				<ul className={"search-results"}>
					{  groupResults }
				</ul>
			</>
	);
};

export const MapStateToProps = (state) => {
	return {
		isSearching: state.groups.isSearching,
		searchResults: state.groups.searchResults
	};
};

export const MapDispatchToProps = (dispatch) => {
	return {
		SearchGroups: (searchTerm) => dispatch(GroupsSearchActionCreator(searchTerm)),
	};
};

export default connect(MapStateToProps, MapDispatchToProps)(GroupSearch);
