import React from 'react';
import MainLayout from './layouts/main-layout';

function App() {
	return (
		<React.Fragment>
			<MainLayout />
		</React.Fragment>
  	);
}

export default App;
