import * as NewsConstants from "../actions/constants/news";

const intitalState = {
	isLoading: false,
	isSaving: false,
	newsItems: [],

};

export const news = (state = intitalState, action) => {
	switch (action.type) {
		case NewsConstants.NEWS_LOAD_REQUESTED:
			return {
				...state,
				isLoading: true,
				newsItems: []
			};
		case NewsConstants.NEWS_LOAD_SUCCEEDED:
			return {
				...state,
				isLoading: false,
				newsItems: action.newsItems
			};
		case NewsConstants.NEWS_LOAD_FAILED:
			return {
				...state,
				isLoading: false,
				newsItems: []
			};
		case NewsConstants.NEWS_SAVE_REQUESTED:
			return {
				...state,
				isSaving: true
			};
		case NewsConstants.NEWS_SAVE_SUCCEEDED:
			return {
				...state,
				isSaving: false
			};
		case NewsConstants.NEWS_SAVE_FAILED:
			return {
				...state,
				isSaving: false
			};
		default:
			return { ...state };
	}
};
