import { call, put, takeLatest } from "redux-saga/effects";

import { authenticatedClient } from "../../services/api-service";
import * as NewsConstants from "../actions/constants/news";

const buildLoadNewsRequest = async (isActiveOnly) => {
	const client = await authenticatedClient();

	let urlPath = '/news';
	if (isActiveOnly) {
		urlPath = '/news/active';
	}

	return client
		.get(urlPath, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

const buildSaveNewsRequest = async (payload) => {
	const client = await authenticatedClient();

	if (payload.id > 0) {
		return client
			.put(`/news/${payload.id}`, payload, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw response;
				}
				return response;
			})
			.catch((error) => {
				return error;
			});
	} else {
		return client
			.post('/news', payload, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw response;
				}
				return response;
			})
			.catch((error) => {
				return error;
			});
	}
}

function* LoadNewsSaga(action) {
	try {
		const response = yield call(buildLoadNewsRequest, action.isActiveOnly);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		yield put({
			type: NewsConstants.NEWS_LOAD_SUCCEEDED,
			newsItems: response.data,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: NewsConstants.NEWS_LOAD_FAILED,
			error: error,
		});
	}
}

function* SaveNewsSaga(action) {
	try {
		const response = yield call(buildSaveNewsRequest, action.newsItem);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}

		alert('News article successfully saved!');
		
		yield put({
			type: NewsConstants.NEWS_SAVE_SUCCEEDED,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: NewsConstants.NEWS_SAVE_FAILED,
			error: error,
		});
	}
}

export default function* notificationsSagas() {
	//Take Latest
	yield takeLatest(NewsConstants.NEWS_LOAD_REQUESTED, LoadNewsSaga);
	yield takeLatest(NewsConstants.NEWS_SAVE_REQUESTED, SaveNewsSaga);
}
