import React from "react";
import App from "../App";
import PrivateRoute from "./private-route";

const Routes = (props) => {
  return (
    <React.Fragment>
      <PrivateRoute path="/" component={App} isLoaded={true} />
    </React.Fragment>
  );
};

export default Routes;