import * as AuthenticationConstants from "../actions/constants/authentication";

const intitalState = {
	isAuthenticated: false,
	firstName: '',
	lastName: '',
	userId: '',
	userName: '',
	isAdmin: false,
	isPushUser: false,
	isLoginFailed: false
};

export const authentication = (state = intitalState, action) => {
	switch (action.type) {
		case AuthenticationConstants.LOGIN_SUCCEEDED:
			return {
				...state,
				isAuthenticated: true,
				firstName: action.decoded.given_name,
				lastName: action.decoded.family_name,
				userId: action.decoded.userId,
				userName: action.decoded.preferred_username,
				isAdmin: action.decoded.IsAdmin === "true",
				isPushUser: action.decoded.IsPushUser === "true",
				isLoginFailed: false,
			};
		case AuthenticationConstants.LOGIN_FAILED:
			return {
				...state,
				isAuthenticated: false,
				firstName: '',
				lastName: '',
				userId: '',
				userName: '',
				isLoginFailed: true,
				isAdmin: false,
				isPushUser: false,
			};
		case AuthenticationConstants.CLEAR_STATE:
			return intitalState;
		case AuthenticationConstants.LOGOUT:
			return intitalState;
		default:
			return { ...state };
	}
};
