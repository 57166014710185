import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

import SideLayout from "./side-layout";
import HeaderLayout from "./header-layout";
import LoginLayout from "./login-layout";
import MainRouter from "../routing/main-router";
import { NavigateAction } from "../redux/actions/creators/navigation";


const MainLayout = (props) => {
	
	useEffect(() => {
		const token = localStorage.getItem("@Token");
		if (token === null && window.location.pathname !== "/PasswordReset") {
			props.navigate("/Login");
		}
	});

	if (!props.isAuthenticated) {
		return (
			<>
				<LoginLayout />
			</>
		)
	}

	return (
		<>
			<HeaderLayout isAuthenticated={props.isAuthenticated} isAdmin={props.isAdmin} isPushUser={props.isPushUser} />
			<Row id="wrapper">
				<Col id="asideContainer" data-qa="asideContainer" md={1}>
					<SideLayout
						user={props.user}
						isAuthenticated={props.isAuthenticated}
						isAdmin={props.isAdmin}
						isPushUser={props.isPushUser}
					/>
				</Col>
				<Col id="contentContainer" md={11}>
					<MainRouter
						user={props.user}
						isAuthenticated={props.isAuthenticated}
						isAdmin={props.isAdmin}
						isPushUser={props.isPushUser}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.authentication.isAuthenticated,
		isAdmin: state.authentication.isAdmin,
		isPushUser: state.authentication.isPushUser
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigate: (path) => dispatch(NavigateAction(path)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
