import { call, put, takeLatest } from "redux-saga/effects";
import qs from "qs";
import jwtDecode from "jwt-decode";
import { push } from "connected-react-router";

import publicClient from "../../services/api-service";
import * as AuthenticationConstants from "../actions/constants/authentication";
import Environment from "../../env/environment";
import store from "../stores/store";

const buildLoginRequest = (payload) =>
	publicClient()
		.post("/connect/token", payload, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});

function* LoginSaga(action) {
	try {
		let data = qs.stringify({
			grant_type: "password",
			client_id: Environment.identity.clientId,
			client_secret: Environment.identity.clientSecret,
			scope: "openid profile",
			username: action.username,
		});
		data = data + "&password=" + action.password;
		
		const tokenInfo = yield call(buildLoginRequest, data);
		
		if (tokenInfo.errors) {
			throw tokenInfo.errors[0].message;
		}

		var decoded = jwtDecode(tokenInfo.data.access_token);
		
		if (decoded.AllsynxClientId !== Environment.clientId) {
			throw new Error('Invalid login.');
		}
		
		localStorage.setItem("@Token", tokenInfo.data.access_token);
		localStorage.setItem("@userId", decoded.UserId);
		localStorage.setItem("@RefreshToken", tokenInfo.data.refresh_token);
		localStorage.setItem("@TokenExpiration", decoded.exp.toString());
		localStorage.setItem("@FirstName", decoded.given_name);
		localStorage.setItem("@LastName", decoded.family_name);
		
		store.dispatch(push("/"));

		yield put({
			type: AuthenticationConstants.LOGIN_SUCCEEDED,
			data: tokenInfo.data,
			decoded: decoded,
			navigation: action.navigation,
		});
	} catch (error) {
		yield put({
			type: AuthenticationConstants.LOGIN_FAILED,
			error: error,
		});
	}
}

function LogoutSaga(action) {
	localStorage.removeItem("@Token");
	localStorage.removeItem("@userId");
	localStorage.removeItem("@RefreshToken");
	localStorage.removeItem("@TokenExpiration");
	localStorage.removeItem("@FirstName");
	localStorage.removeItem("@LastName");

	store.dispatch(push("/"));
}

function ClearState(action) {
	localStorage.removeItem("@Token");
	localStorage.removeItem("@userId");
	localStorage.removeItem("@RefreshToken");
	localStorage.removeItem("@TokenExpiration");
	localStorage.removeItem("@FirstName");
	localStorage.removeItem("@LastName");
}

export default function* authenticationSagas() {
	//Take Latest
	yield takeLatest(AuthenticationConstants.LOGIN_REQUESTED, LoginSaga);
	yield takeLatest(AuthenticationConstants.CLEAR_STATE, ClearState);
	yield takeLatest(AuthenticationConstants.LOGOUT, LogoutSaga);
}
