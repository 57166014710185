import * as NotificationConstants from "../actions/constants/notifications";

const intitalState = {
	isSending: false
};

export const notifications = (state = intitalState, action) => {
	switch (action.type) {
		case NotificationConstants.NOTIFICATION_SEND_REQUESTED:
			return {
				...state,
				isSending: true
			};
		case NotificationConstants.NOTIFICATION_SEND_SUCCEEDED:
			return {
				...state,
				isSending: false
			};
		case NotificationConstants.NOTIFICATION_SEND_FAILED:
			return {
				...state,
				isSending: false
			};
		default:
			return { ...state };
	}
};
