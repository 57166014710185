import React from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";

const Dashboard = (props) => {
	return (
		<>
			<h1>
				Welcome {props.firstName} {props.lastName}!
			</h1>
		</>
	);
};

export const MapStateToProps = (state) => {
	return {
		firstName: state.authentication.firstName,
		lastName: state.authentication.lastName,
		userId: state.authentication.userId,
		userName: state.authentication.userName,
	};
};

export const MapDispatchToProps = (dispatch) => {
	return {
		
	};
};

export default connect(MapStateToProps, MapDispatchToProps)(Dashboard);
