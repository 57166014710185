import React from "react";

const CustomSwitch = (props) => {
	const isLeft = props.isLeftText || false;

	return (
		<>
			<div className="toggle-group" onClick={() => props.onChange()}>
				{ isLeft ? <span className="OptionText">{props.Label}</span> : null }
				<input
					type="checkbox"
					name="on-off-switch"
					id={props.id}
					checked={props.checked}
					onChange={() => console.log('change')}
				/>
				<div className={props.labelTextClassName + " custom-toggle-switch pull-right"} aria-hidden="true">
					<label className="custom-switch-label" htmlFor={props.id}>
						<div className="custom-switch-inner"></div>
						<div className="custom-switch-switch"></div>
					</label>
				</div>
				{ !isLeft ? <span className="OptionText">{props.Label}</span> : null }
			</div>
		</>
	);
};

export default CustomSwitch;
