const replacedClientId = 'REPLACE_CLIENT_ID';
const replacedApiUrl = 'REPLACE_API_URL';

const Environment = {
	environmentName: process.env.NODE_ENV,
	clientId: Number(process.env.REACT_APP_CLIENT_ID || replacedClientId), // 1: MGM, 2: FBS, ...
	baseApiUrl: `https://${process.env.REACT_APP_API_URL || replacedApiUrl}`,// 'https://api.dianoga.echo.thebenefitshub.com',
	identity: {
		clientId: 'BenefitsInfoManage',
		clientSecret: 'SuperSecretPassword'
	}
};

export default Environment;