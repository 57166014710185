import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";

import { ClearStateCreator, LoginActionCreator } from "../../redux/actions/creators/authentication";
import CustomButton from "../../components/custom-button";
import TextBox from "../../components/text-box";
import Title from "../../components/title";

const Login = (props) => {

	const [username, UpdateUsername] = useState("");
	const [password, UpdatePassword] = useState("");
	const [isSubmitted, UpdateSubmitted] = useState(false);
	const [errorMessage, UpdateErrorMessage] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem("@Token");
		if (token === null) {
			props.clearState(props.history);
		}
	}, [localStorage.getItem("@Token")]);

	const handleShowPassword = () => {
		if (!showPassword) {
			setShowPassword(true);
		} else {
			setShowPassword(false);
		}
	};

	const handleLogin = () => {
		if (username === "") {
			UpdateErrorMessage("Username is required");
			return;
		}

		if (password === "") {
			UpdateErrorMessage("Password is required");
			return;
		}

		props.signIn(username, password);
		UpdateSubmitted(true);
	};
	let error = null;
	if (errorMessage != "") {
		error = <Alert variant={"danger"}>{errorMessage}</Alert>;
	}
	if (props.isLoginFailed && isSubmitted) {
		error = <Alert variant={"danger"}>Invalid username/password</Alert>;
	}
	return (
		<div className={"LoginWrapper"}>
			<div className={"LoginContainer"}>
				<Title />
				<TextBox
					Label={"Email Address"}
					placeholder={"someone@example.com"}
					type={"email"}
					onChange={(value) => {
						UpdateUsername(value);
					}}
				/>
				<div className="PasswordWrapper">
					<TextBox
						Label={"Password"}
						type={showPassword ? "text" : "password"}
						onChange={(value) => UpdatePassword(value)}
					/>
					<div className="password-eye" onClick={handleShowPassword}>
						{/* <CustomPasswordShowHide show={showPassword} /> */}
					</div>
				</div>
				{error}
				<CustomButton text={"Login"} onClick={() => handleLogin()} />
				{/* <span className={"registrationSupport"}>
					<NavLink onClick={() => props.history.push("/ForgotPassword")}>
						Forgot Password
					</NavLink>
				</span>
				<div className={"RegisterWrapper"}>
					<span id="registerLink">
						<NavLink onClick={() => props.history.push("/Register")}>
							Register Account
						</NavLink>
					</span>
					<span id="supportLink">
						<NavLink target="_blank" href="https://www.safesynx.com/support">
							Need Help?
						</NavLink>
					</span>
				</div> */}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		signIn: (username, password) =>
			dispatch(LoginActionCreator(username, password)),
		clearState: () => dispatch(ClearStateCreator()),
	};
};

const mapStateToProps = (state) => {
	return {
		isLoginFailed: state.authentication.isLoginFailed,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
