import React from 'react';
import { Route } from 'react-router-dom';

import CustomSpinner from '../components/custom-spinner';

const PrivateRoute = ({ component: Component, isLoaded, ...rest}) => (
    <Route {...rest} render={(props) => (
        isLoaded === true
          ? <Component {...props} />
          : <CustomSpinner />
    )} />
);

export default PrivateRoute;