import * as GroupsConstants from "../constants/groups";

export const GroupsSearchActionCreator = (searchTerm) => {
	return {
		type: GroupsConstants.GROUP_SEARCH_REQUESTED,
		searchTerm: searchTerm
	};
};

export const GroupLoadActionCreator = (groupId) => {
	return {
		type: GroupsConstants.GROUP_LOAD_REQUESTED,
		groupId: groupId
	};
};

export const GroupLoadActiveActionCreator = () => {
	return {
		type: GroupsConstants.GROUP_LOAD_ACTIVE_REQUESTED
	};
};

export const GroupSaveActionCreator = (groupData) => {
	return {
		type: GroupsConstants.GROUP_SAVE_REQUESTED,
		groupData: groupData
	};
};

export const DownloadDevicesReportActionCreator = () => {
	return {
		type: GroupsConstants.DOWNLOAD_DEVICES_REPORT_REQUESTED
	};
};