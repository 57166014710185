import { all } from "redux-saga/effects";

import authenticationSagas from "./authentication";
import groupsSagas from "./groups";
import navigationSagas from "./navigation";
import notificationsSagas from "./notifications";
import newsSagas from "./news";
import usersSagas from "./users";

export default function* rootSaga() {
	yield all([
		authenticationSagas(),
		navigationSagas(),
		groupsSagas(),
		notificationsSagas(),
		newsSagas(),
		usersSagas()
	]);
}
