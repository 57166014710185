import * as NewsConstants from "../constants/news";

export const NewsLoadCreator = (isActiveOnly) => {
	return {
		type: NewsConstants.NEWS_LOAD_REQUESTED,
		isActiveOnly: isActiveOnly
	};
};

export const NewsSaveCreator = (newsItem) => {
	return {
		type: NewsConstants.NEWS_SAVE_REQUESTED,
		newsItem: newsItem
	};
};